function RegionSelector({ onRegionSelect, simulationResults, currentRegion }) {
  if (!simulationResults || !onRegionSelect) {
    return null;
  }
  return (
    <div>
      <p>⬇️ Select region ⬇️</p>
      <select value={currentRegion} onChange={onRegionSelect}>
        {Object.keys(simulationResults).map((key, idx) => (
          <option value={key}>{key}</option>
        ))}
      </select>
    </div>
  );
}

export default RegionSelector;
