import "./App.css";
import React, { useState, useEffect } from "react";
import RegionSelector from "./RegionSelector";
import OrchSelector from "./OrchSelector";
import PriceSelector from "./PriceSelector";
import Table from "./Table";

function App() {
  const [stronkData, setStronkData] = useState(null); //< Raw data from Stronk Broadcasters
  const [explorerData, setExplorerData] = useState(null); //< Raw data from the explorer
  const [performanceStats, setPerformanceStats] = useState(null);
  const [regionalData, setData] = useState(null); //< Processsed stronk & explorer data per region
  const [simulationResults, setResults] = useState(null); //< Results of the simulation
  const [useExplorerPricing, setUseExplorerPricing] = useState(false); //< When true, prefers Explorer data over Stronk Broadcaster data
  const [currentRegion, setRegion] = useState("SIN"); //< Name of Livepeer performance region to display distributions for
  const [currentOrchestrator, setOrchestrator] = useState(
    "0x847791cbf03be716a7fe9dc8c9affe17bd49ae5e"
  ); //< ETH address of current Orchestrator
  const [currentPrice, setPrice] = useState(0); //< Price of current selected Orchestrator. If 0 takes pricing from B data
  // Selection params
  const [stakeWeight, setStakeWeight] = useState(0.7); //< Broadcaster weight of stake based selection
  const [priceWeight, setPriceWeight] = useState(0.3); //< Broadcaster weight of price based selection
  const [expFactor, setExpFactor] = useState(100); //< Broadcaster exponent factor for price weighted selection
  const [maxPriceVOD, setMaxPriceVOD] = useState(700); //< Broadcaster Price cutoff for VOD streams
  const [maxPriceLive, setMaxPriceLive] = useState(920); //< Broadcaster Price cutoff for livestreams
  const [vodPerfThreshold, setVodThreshold] = useState(0.3); //< Broadcaster minimum performance score for VOD jobs
  const [livePerfThreshold, setLiveThreshold] = useState(0.65); //< Broadcaster minimum performance threshold for live jobs
  const [vodWeight, setVodWeight] = useState(0.1); //< Chance of a VOD stream vs a Live stream
  const [streamAmount, setStreamAmount] = useState(1000); //< Amount of streams to simulate
  const [selectionMethod, setMethod] = useState("arithmetic"); //< Name of Livepeer performance region to display distributions for

  //
  // One-time retrieval of API data
  //
  const getData = () => {
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    // Retrieve Stronk Broadcaster data
    fetch("https://stronk.rocks/orch/json", requestOptions)
      .then((response) => response.json())
      .then((result) => setStronkData(result))
      .catch((error) => console.log("error", error));
    // Retrieve performance data
    fetch(
      "https://leaderboard-serverless.vercel.app/api/aggregated_stats",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => setPerformanceStats(result))
      .catch((error) => console.log("error", error));
    // Retrieve Explorer data
    fetch(
      "https://nyc.livepeer.com/orchestratorStats?excludeUnavailable=True",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        let explorerData = {};
        // Turn list into dict for easier lookups
        for (const obj of result) {
          explorerData[obj.Address] = obj;
        }
        setExplorerData(explorerData);
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    // Retrieve API data once on initial render
    getData();
  }, []);

  //
  // Simulate selection process
  //

  const simulateSelection = () => {
    if (!regionalData) {
      return;
    }

    let simulationResults = {};
    Object.entries(regionalData).map(([regionName, regionObj]) => {
      // VOD probabilities and LIVE probabilities separate
      let stakeSumVod = 0.0;
      let priceProbSumVod = 0.0;
      let stakeSumLive = 0.0;
      let priceProbSumLive = 0.0;
      console.log("(Init) Parsing regional data for " + regionName);
      if (!simulationResults[regionName]) {
        simulationResults[regionName] = {};
      }
      // First run to set above sums and init simulation table for each Orch
      Object.entries(regionObj).map(([orchId, orchObj]) => {
        console.log("Parsing regional data for " + orchId);
        const thisPriceProb = Math.exp((-1 * orchObj.price) / expFactor);
        let eligibleVOD = true;
        let eligibleLive = true;
        if (maxPriceVOD < orchObj.price || orchObj.price < 1) {
          eligibleVOD = false;
          console.log("Not eligible for VOD jobs due to price");
        }
        if (maxPriceLive < orchObj.price || orchObj.price < 1) {
          eligibleLive = false;
          console.log("Not eligible for Live jobs due to price");
        }
        if (vodPerfThreshold > orchObj.score) {
          eligibleVOD = false;
          console.log("Not eligible for VOD jobs due to performance");
        }
        if (livePerfThreshold > orchObj.score) {
          eligibleLive = false;
          console.log("Not eligible for Live jobs due to performance");
        }
        // Init orch data
        simulationResults[regionName][orchId] = {
          name: orchObj.name,
          price: orchObj.price,
          score: orchObj.score,
          stake: orchObj.stake,
          priceProb: thisPriceProb,
          vodStreams: 0,
          liveStreams: 0,
          expectedValue: 0,
        };
        // Count sums for final probabilities
        if (eligibleVOD) {
          stakeSumVod += orchObj.stake;
          priceProbSumVod += thisPriceProb;
        }
        if (eligibleLive) {
          stakeSumLive += orchObj.stake;
          priceProbSumLive += thisPriceProb;
        }
      });
      // Second run to calculate actual probabilities
      let vodProbSum = 0.0;
      let liveProbSum = 0.0;
      Object.entries(regionObj).map(([orchId, orchObj]) => {
        console.log("(Calc) Parsing regional data for " + orchId);
        simulationResults[regionName][orchId].priceProbLive =
          simulationResults[regionName][orchId].priceProb / priceProbSumLive;
        simulationResults[regionName][orchId].priceProbVod =
          simulationResults[regionName][orchId].priceProb / priceProbSumVod;
        simulationResults[regionName][orchId].stakeProbLive =
          simulationResults[regionName][orchId].stake / stakeSumLive;
        simulationResults[regionName][orchId].stakeProbVod =
          simulationResults[regionName][orchId].stake / stakeSumVod;
        if (selectionMethod == "arithmetic") {
          simulationResults[regionName][orchId].probLive =
            stakeWeight * simulationResults[regionName][orchId].stakeProbLive +
            priceWeight * simulationResults[regionName][orchId].priceProbLive;
          simulationResults[regionName][orchId].probVod =
            stakeWeight * simulationResults[regionName][orchId].stakeProbVod +
            priceWeight * simulationResults[regionName][orchId].priceProbVod;
        } else if (selectionMethod == "geometric") {
          simulationResults[regionName][orchId].probLive =
            Math.pow(
              simulationResults[regionName][orchId].stakeProbLive,
              stakeWeight
            ) *
            Math.pow(
              simulationResults[regionName][orchId].priceProbLive,
              priceWeight
            );
          simulationResults[regionName][orchId].probVod =
            Math.pow(
              simulationResults[regionName][orchId].stakeProbVod,
              stakeWeight
            ) *
            Math.pow(
              simulationResults[regionName][orchId].priceProbVod,
              priceWeight
            );
        } else if (selectionMethod == "harmonic") {
          simulationResults[regionName][orchId].probLive =
            1 /
            (stakeWeight / simulationResults[regionName][orchId].stakeProbLive +
              priceWeight /
                simulationResults[regionName][orchId].priceProbLive);
          simulationResults[regionName][orchId].probVod =
            1 /
            (stakeWeight / simulationResults[regionName][orchId].stakeProbVod +
              priceWeight / simulationResults[regionName][orchId].priceProbVod);
        }
        if (maxPriceVOD < orchObj.price || orchObj.price < 1) {
          simulationResults[regionName][orchId].probVod = 0.0;
          simulationResults[regionName][orchId].stakeProbVod = 0.0;
          simulationResults[regionName][orchId].priceProbVod = 0.0;
        }
        if (maxPriceLive < orchObj.price || orchObj.price < 1) {
          simulationResults[regionName][orchId].probLive = 0.0;
          simulationResults[regionName][orchId].stakeProbLive = 0.0;
          simulationResults[regionName][orchId].priceProbLive = 0.0;
        }
        if (vodPerfThreshold > orchObj.score) {
          simulationResults[regionName][orchId].probVod = 0.0;
          simulationResults[regionName][orchId].stakeProbVod = 0.0;
          simulationResults[regionName][orchId].priceProbVod = 0.0;
        }
        if (livePerfThreshold > orchObj.score) {
          simulationResults[regionName][orchId].probLive = 0.0;
          simulationResults[regionName][orchId].stakeProbLive = 0.0;
          simulationResults[regionName][orchId].priceProbLive = 0.0;
        }
        vodProbSum += simulationResults[regionName][orchId].probVod;
        liveProbSum += simulationResults[regionName][orchId].probLive;
      });
      simulationResults[regionName].stakeSumVod = stakeSumVod;
      simulationResults[regionName].priceProbSumVod = priceProbSumVod;
      simulationResults[regionName].stakeSumLive = stakeSumLive;
      simulationResults[regionName].priceProbSumLive = priceProbSumLive;
      simulationResults[regionName].vodProbSum = vodProbSum;
      simulationResults[regionName].liveProbSum = liveProbSum;
    });

    // Calculate final values
    Object.entries(simulationResults).map(([regionName, regionObj]) => {
      Object.entries(regionObj).map(([orchId, orchObj]) => {
        if (typeof orchObj !== "object") {
          return;
        }
        simulationResults[regionName][orchId].liveStreams = Math.floor(
          streamAmount * (1 - vodWeight) * orchObj.probLive
        );
        simulationResults[regionName][orchId].vodStreams = Math.floor(
          streamAmount * vodWeight * orchObj.probVod
        );
        simulationResults[regionName][orchId].expectedValue +=
          simulationResults[regionName][orchId].liveStreams *
          simulationResults[regionName][orchId].price;
        simulationResults[regionName][orchId].expectedValue +=
          simulationResults[regionName][orchId].vodStreams *
          simulationResults[regionName][orchId].price;
      });
    });
    setResults(simulationResults);
  };

  useEffect(() => {
    // Run simulation if relevant params change or there's new regional data
    simulateSelection();
  }, [
    priceWeight,
    stakeWeight,
    vodWeight,
    expFactor,
    streamAmount,
    maxPriceVOD,
    maxPriceLive,
    regionalData,
    selectionMethod,
  ]);

  //
  // Processing API data + current Orch pice into a regional table
  //

  useEffect(() => {
    if (!stronkData || !explorerData || !performanceStats) {
      return;
    }
    let newRegions = {};
    const now = new Date();
    // Iterate over Stronk-accessible Orchestrators
    Object.entries(stronkData).map(([orchId, orchObj]) => {
      console.log("Parsing data for " + orchId);
      if (!explorerData[orchId]) {
        console.log(
          "Orchestrator not available in Explorer API, skipping " + orchId
        );
        return;
      }
      // Iterate over instances found at the Orchs service URI
      Object.entries(orchObj.instances).map(([ip, instanceObj]) => {
        console.log("Found instance " + ip);
        // Iterate over Livepeer test stream regions near this instance
        Object.entries(instanceObj.livepeer_regions).map(
          ([regionName, regionObj]) => {
            console.log("Instance was active in region " + regionName);
            // Skip if the region was last associated more than a day ago
            if (regionObj.lastTime - now > 86400000) {
              console.log(
                "Activity in this region was too long ago, skipping " + orchId
              );
              return;
            }
            let latency = 0.0;
            if (regionName == "SAO") {
              console.log("Skipping SAO region");
              return;
            }
            console.log(orchObj.regionalStats);
            if (regionName == "SIN") {
              latency = orchObj.regionalStats["Singapore"].avgDiscoveryTime;
            }
            if (
              regionName == "FRA" ||
              regionName == "PRG" ||
              regionName == "LON"
            ) {
              latency = orchObj.regionalStats["Leiden"].avgDiscoveryTime;
            }
            if (regionName == "LAX") {
              latency = orchObj.regionalStats["Las Vegas"].avgDiscoveryTime;
            }
            if (regionName == "MDW" || regionName == "NYC") {
              latency = orchObj.regionalStats["Michigan"].avgDiscoveryTime;
            }
            if (latency > 500) {
              console.log(
                "Latency of " + latency + " is too high, skipping " + orchId
              );
              return;
            }
            // Determine price
            let price = 0;
            // Init price to local value if it's the selected orchestrator
            if (currentOrchestrator == orchId) {
              price = currentPrice;
            }
            // Override with Stronk pricing if the price is unset
            if (price < 1) {
              price = instanceObj.price;
            }
            // Override with Explorer pricing if the price is unset or override is set
            if (
              price < 1 ||
              (useExplorerPricing && currentOrchestrator != orchId)
            ) {
              if (explorerData[orchId].PricePerPixel > 0) {
                price = explorerData[orchId].PricePerPixel;
              }
            }
            // Skip if there's no valid price to be found
            if (price < 1) {
              console.log(
                "Unable to find valid pricing for this orchestrator instance, skipping " +
                  orchId
              );
              return;
            }
            // Update regional data for the simulation to use
            if (!newRegions[regionName]) {
              newRegions[regionName] = {};
            }
            if (performanceStats[orchId] && performanceStats[orchId][regionName]){
              newRegions[regionName][orchId] = {
                name: orchObj.name,
                price: price,
                score: performanceStats[orchId][regionName].score || 0.0,
                stake: explorerData[orchId].DelegatedStake,
                latency: latency,
              };
            }
          }
        );
      });
    });
    setData(newRegions);
  }, [
    stronkData,
    performanceStats,
    explorerData,
    currentOrchestrator,
    currentPrice,
    useExplorerPricing,
  ]);

  //
  // Controls
  //

  let onRegionSelect = (e) => {
    console.log("Selected region " + e.target.value);
    setRegion(e.target.value);
  };

  let onOrchSelect = (e) => {
    console.log("Selected orchestrator " + e.target.value);
    setOrchestrator(e.target.value);
  };

  let onPrice = (e) => {
    console.log("New price " + e.target.value);
    setPrice(Number(e.target.value));
  };

  let onUseExplorerPricing = (e) => {
    setUseExplorerPricing(!useExplorerPricing);
  };

  let onPriceWeight = (e) => {
    const priceVal = Math.max(Math.min(e.target.value / 100, 1.0), 0.0).toFixed(
      2
    );
    const stakeVal = (1 - priceVal).toFixed(2);
    console.log("New price weight " + priceVal);
    console.log("New stake weight " + stakeVal);
    setPriceWeight(priceVal);
    setStakeWeight(stakeVal);
  };

  let onStakeWeight = (e) => {
    const stakeVal = Math.max(Math.min(e.target.value / 100, 1.0), 0.0).toFixed(
      2
    );
    const priceVal = (1 - stakeVal).toFixed(2);
    console.log("New price weight " + priceVal);
    console.log("New stake weight " + stakeVal);
    setPriceWeight(priceVal);
    setStakeWeight(stakeVal);
  };

  let onExpFactor = (e) => {
    setExpFactor(e.target.value);
  };

  let onVodWeight = (e) => {
    console.log("New chance on VOD stream " + e.target.value);
    const val = Math.max(Math.min(e.target.value / 100, 1.0), 0.0).toFixed(2);
    setVodWeight(val);
  };

  let onMethodSelect = (e) => {
    console.log("Selected method " + e.target.value);
    setMethod(e.target.value);
  };

  //
  // TODO:S
  //

  // console.log(simulationResults);
  // {
  //   "FRA" : {
  //     "name": "navigare-necesse-est.eth",
  //     "price": 100,
  //     "score": 0.9897459741131963,
  //     "stake": 1.100953071283292e+23,
  //     "priceProb": 0.36787944117144233,
  //     "vodStreams": 2.836472315524946,
  //     "liveStreams": 27.43957004631711,
  //     "expectedValue": 3027604.2361841835,
  //     "priceProbLive": 0.03732633543984918,
  //     "priceProbVod": 0.03515611611224723,
  //     "stakeProbLive": 0.014533254515600483,
  //     "stakeProbVod": 0.01251813958892133,
  //     "probLive": 0.03048841116257457,
  //     "probVod": 0.028364723155249457
  //   }
  // }

  // Component to draw price probability curve
  // price_prob = (np.exp(-1 * x / expFactor)) / price_prob_sum
  //  we might want a 'raw' curve and a derived price_prob curve

  // Component to graph stake probability curve
  // orchs['stake_prob'] = orchs['stake'] / orchs['stake'].sum()
  // Dots for each stake amount found in orch list

  // Component to print orch Table
  // Component to graph orch table based on EV

  return (
    <div className="App">
      <p>
        Selected Orchestrator {currentOrchestrator} in {currentRegion}
      </p>
      <p>
        Capped VOD on {maxPriceVOD} and Live on {maxPriceLive} PPP
      </p>
      <div>
        <p>⬇️ Select method ⬇️</p>
        <select value={selectionMethod} onChange={onMethodSelect}>
          <option value={"arithmetic"}>Arithmetic mean</option>
          <option value={"geometric"}>Geometric mean</option>
          <option value={"harmonic"}>Harmonic mean</option>
        </select>
        <p>
          `Arithmetic` is the current method. `Geometric` and `Harmonic` are two
          alternative curves which require orchestrators to be competitive on
          both price and stake.
        </p>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <RegionSelector
          onRegionSelect={onRegionSelect}
          simulationResults={simulationResults}
          currentRegion={currentRegion}
        />
        <OrchSelector
          onOrchSelect={onOrchSelect}
          simulationResults={simulationResults}
          currentRegion={currentRegion}
          currentOrchestrator={currentOrchestrator}
        />
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          <p>⬇️ Price Weight ⬇️</p>
          <input
            value={priceWeight * 100}
            onChange={onPriceWeight}
            type="number"
            min="0"
            max="100"
          />
        </div>
        <div>
          <p>⬇️ Stake Weight ⬇️</p>
          <input
            value={stakeWeight * 100}
            onChange={onStakeWeight}
            type="number"
            min="0"
            max="100"
          />
        </div>
        <div>
          <p>⬇️ Price Exp factor ⬇️</p>
          <input
            value={expFactor}
            onChange={onExpFactor}
            type="number"
            min="1"
            max="10000"
          />
        </div>
        <div>
          <p>⬇️ Chance on VOD stream ⬇️</p>
          <input
            value={vodWeight * 100}
            onChange={onVodWeight}
            type="number"
            min="1"
            max="100"
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p>
          {useExplorerPricing
            ? "Using global Explorer pricing"
            : "Using localized Stronk Broadcaster pricing"}
          . Flip to switch to{" "}
          {useExplorerPricing
            ? " localized Stronk Broadcaster pricing"
            : " global Explorer pricing"}
          .&nbsp;&nbsp;
        </p>
        <label class="switch">
          <input
            type="checkbox"
            checked={!useExplorerPricing}
            onChange={onUseExplorerPricing}
          />
          <span class="toggle" />
        </label>
      </div>
      <PriceSelector
        currentPrice={currentPrice}
        onPrice={onPrice}
        currentOrchestrator={currentOrchestrator}
        currentRegion={currentRegion}
      />
      <p>
        Distribution with a {(vodWeight * 100).toFixed(0)}% chance of a VOD
        stream
      </p>
      <Table
        currentOrchestrator={currentOrchestrator}
        currentRegion={currentRegion}
        simulationResults={simulationResults}
      />
    </div>
  );
}

export default App;
