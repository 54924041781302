function OrchSelector({
  onOrchSelect,
  simulationResults,
  currentRegion,
  currentOrchestrator,
}) {
  if (!onOrchSelect || !simulationResults || currentRegion == "") {
    return null;
  }

  // Sort on name
  var sortedObjs = Object.keys(simulationResults[currentRegion]).map(function (
    key
  ) {
    return [key, simulationResults[currentRegion][key]];
  });
  sortedObjs.sort(function (first, second) {
    if (first[1].name < second[1].name) {
      return -1;
    }
    if (first[1].name > second[1].name) {
      return 1;
    }
    return 0;
  });

  return (
    <div>
      <p>⬇️ Select orchestrator ⬇️</p>
      <select value={currentOrchestrator} onChange={onOrchSelect}>
        {sortedObjs.map(([orchId, orchObj]) =>
          typeof orchObj === "object" ? (
            <option value={orchId}>{orchObj.name}</option>
          ) : null
        )}
      </select>
    </div>
  );
}

export default OrchSelector;
