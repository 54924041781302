function PriceSelector({
  currentPrice,
  onPrice,
  currentOrchestrator,
  currentRegion,
}) {
  if (!onPrice || currentOrchestrator == "" || currentRegion == "") {
    return null;
  }
  return (
    <div>
      <p>{currentPrice > 0 ? "Set price for " + currentOrchestrator + " to " + currentPrice : "Using default price for " + currentOrchestrator}</p>
      <input
        type="range"
        min="0"
        max="1200"
        value={currentPrice}
        onChange={onPrice}
        class="slider"
        id="myRange"
      />
    </div>
  );
}

export default PriceSelector;
