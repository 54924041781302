function Table({ simulationResults, currentOrchestrator, currentRegion }) {
  if (!simulationResults || currentOrchestrator == "" || currentRegion == "") {
    return null;
  }

  // Sort on EV
  var sortedObjs = Object.keys(simulationResults[currentRegion]).map(function(key) {
    return [key, simulationResults[currentRegion][key]];
  });
  sortedObjs.sort(function(first, second) {
    return second[1].expectedValue - first[1].expectedValue;
  });

  return (
    <table style={{border: "none", borderCollapse: "collapse"}}>
      <tr>
        <th>Orchestrator</th> {/* key: name */}
        <th className="leftDividerLine">Price</th> {/* key: price */}
        <th>Performance</th> {/* key: score */}
        <th>Stake</th> {/* key: stake */}
        {/* priceProb: pre-normalized price prob */}
        <th className="leftDividerLine">% on price</th> {/* key: priceProbLive */}
        <th>% on stake</th> {/* key: stakeProbLive */}
        <th>% sum for LIVE</th> {/* key: probLive */}
        <th className="leftDividerLine">% on price</th> {/* key: priceProbVod */}
        <th>% on stake</th> {/* key: stakeProbVod */}
        <th>% sum for VOD</th> {/* key: probVod */}
        <th className="leftDividerLine">VOD streams</th> {/* key: vodStreams */}
        <th>Live streams</th> {/* key: liveStreams */}
        <th>Value</th> {/* key: expectedValue */}
      </tr>
      {sortedObjs.map(
        ([orch, orchObj]) => {
          if (typeof orchObj !== "object") {
            return;
          }
          return (
            <tr>
              <td style={currentOrchestrator == orch ? {fontWeight: "bolder", fontSize: "large"} : {}}>
                {orchObj["name"].substring(0, 32)}
              </td>
              <td style={currentOrchestrator == orch ? {fontWeight: "bolder", fontSize: "large"} : {}}>{orchObj["price"].toFixed(1)}</td>
              <td style={currentOrchestrator == orch ? {fontWeight: "bolder", fontSize: "large"} : {}}>{(orchObj["score"] * 10).toFixed(1)}</td>
              <td style={currentOrchestrator == orch ? {fontWeight: "bolder", fontSize: "large"} : {}}>{(orchObj["stake"] / 1e18).toFixed(2)}</td>
              <td className="leftDividerLine" style={currentOrchestrator == orch ? {fontWeight: "bolder", fontSize: "large"} : {}}>{(orchObj["priceProbLive"] * 100).toFixed(2)}%</td>
              <td style={currentOrchestrator == orch ? {fontWeight: "bolder", fontSize: "large"} : {}}>{(orchObj["stakeProbLive"] * 100).toFixed(2)}%</td>
              <td style={currentOrchestrator == orch ? {fontWeight: "bolder", fontSize: "large"} : {}}>{(orchObj["probLive"] * 100).toFixed(2)}%</td>
              <td className="leftDividerLine" style={currentOrchestrator == orch ? {fontWeight: "bolder", fontSize: "large"} : {}}>{(orchObj["priceProbVod"] * 100).toFixed(2)}%</td>
              <td style={currentOrchestrator == orch ? {fontWeight: "bolder", fontSize: "large"} : {}}>{(orchObj["stakeProbVod"] * 100).toFixed(2)}%</td>
              <td style={currentOrchestrator == orch ? {fontWeight: "bolder", fontSize: "large"} : {}}>{(orchObj["probVod"] * 100).toFixed(2)}%</td>
              <td className="leftDividerLine" style={currentOrchestrator == orch ? {fontWeight: "bolder", fontSize: "large"} : {}}>{orchObj["vodStreams"]}</td>
              <td style={currentOrchestrator == orch ? {fontWeight: "bolder", fontSize: "large"} : {}}>{orchObj["liveStreams"]}</td>
              <td style={currentOrchestrator == orch ? {fontWeight: "bolder", fontSize: "large"} : {}}>{(orchObj["expectedValue"] / 1000).toFixed(1)} K</td>
            </tr>
          );
        }
      )}
    </table>
  );
}

export default Table;
